<template>
  <div class="admin-emergency-types">
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/settings')"
          icon="left"
        ></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Incident Type Groups
      </h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search groups"
          v-model="searchQuery"
        />
        <!-- / Search -->

        <a-button
          @click.prevent="loadIncidentTypes"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          @click.prevent="createIncidentTypeGroup"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>
    <!-- List wrapper -->
    <div class="incident-types-list-wrapper">
      <a-alert
        style="margin-bottom: 25px"
        message="Chosen when making an incident, it assigns to a response team and creates any tasks."
      >
      </a-alert>

      <div class="incident-type-org-context-switcher-wrapper">
        <playbook-org-context-switcher
          @set-owner-id-filter="setOwnerIdFilter"
          :owner-id-filter="ownerIdFilter"
        ></playbook-org-context-switcher>
      </div>

      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No incident types -->
      <a-alert
        v-if="!isLoading && incidentTypesToShow.length == 0"
        type="info"
        message="No incident type groups to show"
      >
      </a-alert>
      <!-- / No incident types -->

      <!-- Loaded -->
      <div
        class="incident-types-list"
        v-if="!isLoading && incidentTypesToShow.length"
      >
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="incidentType in incidentTypesToShow"
            :key="incidentType.id"
          >
            <incident-type-list-item
              :all-incident-types="incidentTypes"
              :incident-type="incidentType"
              :tenant-id="tenantId"
              :org-id="selectedOrganisation.id"
              :skills="skills"
              @selected="() => navigateToIncidentTypeGroup(incidentType)"
              :hide-properties="true"
            ></incident-type-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IncidentTypeListItem from "./IncidentTypes/IncidentTypeListItem.vue";
import incidentTypes from "../../../api/incident-types";
import PlaybookOrgContextSwitcher from "../../../components/Admin/Playbooks/PlaybookOrgContextSwitcher.vue";
const _ = require("lodash");
export default {
  components: { IncidentTypeListItem, PlaybookOrgContextSwitcher },

  data() {
    return {
      windowWidth: window.innerWidth,
      isLoading: false,
      incidentTypes: [],
      searchQuery: "",
      ownerIdFilter: null,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.ownerIdFilter = this.selectedOrganisation.id;
    this.loadIncidentTypes();
  },

  watch: {
    selectedOrganisation() {
      this.loadIncidentTypes();
    },
  },

  methods: {
    setOwnerIdFilter(val) {
      this.ownerIdFilter = val;
    },

    navigateToIncidentTypeGroup(incidentType) {
      this.$router.push("/admin/settings/incident-type-groups/" + incidentType.id + '/edit');
    },

    createIncidentTypeGroup() {
      this.$router.push("/admin/settings/incident-type-groups/create");
    },

    loadIncidentTypes() {
      let vm = this;
      vm.isLoading = true;
      vm.playbooks = [];
      incidentTypes
        .getIncidentTypes(this.tenantId, this.selectedOrganisation.id, true)
        .then((r) => {
          vm.incidentTypes = r.data;
          vm.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading incident types");
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
      skills: "skills",
    }),

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    incidentTypesToShow() {
      let filtered = _.filter(this.incidentTypes, (incidentType) => {
        if (this.searchQuery.trim().length) {
          return incidentType.displayName
            .toLowerCase()
            .includes(this.searchQuery.trim().toLowerCase());
        } else {
          return true;
        }
      });
            // Don't show sos  (type == 1)
      filtered = _.filter(filtered, (incidentType) => {
        return incidentType.purpose !== 1;
      });
      // Only show those without a parent id - these are the groups
      filtered = _.filter(filtered, (incidentType) => {
        return incidentType.incidentTypeGroupId == undefined;
      });
      let ordered = _.orderBy(filtered, ["rank", "displayName"], ["desc"]);
      return _.filter(ordered, (incidentType) => {
        return incidentType.ownerId == this.ownerIdFilter;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.incident-type-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}

.incident-type-org-context-switcher-wrapper {
  margin-bottom: 25px;
}
</style>